
import { computed, ComputedRef, defineComponent, onMounted, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useWorkStatistics } from '@/composables/api';
import {
  DEFAULT_PAGE_SIZE,
  SortOrder,
  WorkStatisticsSortKey,
  exportWorkStatisticsExcel,
  getAgents,
  getClassifications,
  getActors,
  getGenres,
  getTags,
  MAX_PER_PAGE,
  OrderSortKey
} from '@/services/api';
import { formatLocalTime, formatISOString } from '@/utils/format-time';
import SearchBox from '@/components/keyword-searching/Index.vue';
import { Agent, Classification, Genre, Tag, Work, WorkStatus } from '@/interfaces';
import Filter, { FilterEvent, FilterOption, FilterType } from '@/components/filter/Index.vue';
import { getStatusTagType, getThousandSeparator } from '@/utils/render';
import download from 'downloadjs';
import dayjs from 'dayjs';
import { isEmpty, join, map } from 'lodash';
import { useKeyword, usePage, useSort } from '@/composables';

export default defineComponent({
  components: {
    SearchBox,
    Filter
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const { keyword } = useKeyword();
    const { setPageQuery, page, pageSize } = usePage(1, DEFAULT_PAGE_SIZE);

    const yesterdayStart = dayjs().subtract(1, 'day').toISOString();
    const yesterdayEnd = dayjs(`${dayjs().subtract(1, 'day').format('YYYY-MM-DD')} 23:59:59`).toISOString();

    const startDate = ref();
    const endDate = ref();
    const dateRange = ref(null);
    const status = ref();
    const classifications = ref<Classification[]>([]);
    const classificationId = ref();
    const classificationOptions = computed(() => classifications.value.map(({ name, id }) => ({ label: `${name}`, value: `${id}` })));
    const agentId = ref();
    const agents = ref<Agent[]>([]);
    const agentOptions = computed(() => agents.value.map(({ name, id }) => ({ label: `${name}`, value: `${id}` })));
    const genreId = ref();
    const genres = ref<Genre[]>([]);
    const genreOptions = computed(() => genres.value.map(({ name, id }) => ({ label: `${name}`, value: `${id}` })));
    const actorId = ref();
    const actors = ref<Genre[]>([]);
    const actorOptions = computed(() => actors.value.map(({ name, id }) => ({ label: `${name}`, value: `${id}` })));
    const tagId = ref();
    const tags = ref<Tag[]>([]);
    const tagOptions = computed(() => tags.value.map(({ name, id }) => ({ label: `${name}`, value: `${id}` })));

    const { sortKey, sortOrder } = useSort<OrderSortKey>();
    const { data, isFetching, isLoading } = useWorkStatistics({ startDate, endDate, page, pageSize, sortKey, sort: sortOrder, keyword, agentId, classificationId, actorId, genreId, tagId, status });

    const sortChange = ({ prop, order }) => {
      sortKey.value = prop;
      order === 'ascending'
        ? sortOrder.value = SortOrder.ASCENDING
        : sortOrder.value = SortOrder.DESCENDING;

      if (!prop) {
        sortOrder.value = null;
      }
    };

    const fetchClassifications = async() => {
      classifications.value = (await getClassifications({ query: { pageSize: MAX_PER_PAGE } })).data;
    };

    const fetchAgents = async() => {
      agents.value = (await getAgents({ query: { pageSize: MAX_PER_PAGE } })).data;
    };

    const fetchGenres = async() => {
      genres.value = (await getGenres({ query: { pageSize: MAX_PER_PAGE } })).data;
    };

    const fetchActors = async() => {
      actors.value = (await getActors({ query: { pageSize: MAX_PER_PAGE } })).data;
    };

    const fetchTags = async() => {
      tags.value = (await getTags({ query: { pageSize: MAX_PER_PAGE } })).data;
    };

    const handleFilterChange = (event: FilterEvent) => {
      // mutate ref
      agentId.value = event[0];
      classificationId.value = event[1];
      actorId.value = event[2];
      genreId.value = event[3];
      tagId.value = event[4];
      status.value = event[5];
    };

    const handleDateRangeChange = () => {
      if (!dateRange.value) {
        startDate.value = '';
        endDate.value = '';
        return;
      }
      startDate.value = formatISOString(dateRange.value[0]);
      endDate.value = formatISOString(dateRange.value[1]);
    };

    const handleExcelExport = async() => {
      const data = await exportWorkStatisticsExcel({
        query: {
          startDate: startDate.value,
          endDate: endDate.value,
          sortKey: sortKey.value,
          sort: sortOrder.value,
          keyword: keyword.value,
          agentId: agentId.value,
          classificationId: classificationId.value,
          actorId: actorId.value,
          genreId: genreId.value,
          tagId: tagId.value,
          status: status.value
        }
      });

      const statisticsStartDate = dayjs(`${startDate.value}`).format('YYYYMMDD');
      const statisticsEndDate = dayjs(`${endDate.value}`).format('YYYYMMDD');

      // 下載匯出檔案
      download(
        data,
       `數據 影片播放數 ${statisticsStartDate}-${statisticsEndDate}.xlsx`,

       'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      );
    };

    const checkStatusIsComingSoon = (work: Work) => {
      const publishDateTimestamp = dayjs(work.publishedAt);
      const now = dayjs();
      return (publishDateTimestamp > now) && (work.status === WorkStatus.PUBLISHED);
    };

    onMounted(() => {
      handleDateRangeChange();
      fetchClassifications();
      fetchActors();
      fetchAgents();
      fetchGenres();
      fetchTags();
    });

    const filterOptions: ComputedRef<FilterOption[]> = computed(() => {
      return [
        {
          type: FilterType.SELECTOR,
          label: '代理商',
          placeholder: '請選擇',
          options: agentOptions.value
        },
        {
          type: FilterType.SELECTOR,
          label: '主類型',
          placeholder: '請選擇',
          options: classificationOptions.value
        },
        {
          type: FilterType.SELECTOR,
          label: '演員',
          placeholder: '請選擇',
          options: actorOptions.value
        },
        {
          type: FilterType.SELECTOR,
          label: '影片類型',
          placeholder: '請選擇',
          options: genreOptions.value
        },
        {
          type: FilterType.SELECTOR,
          label: '標籤',
          placeholder: '請選擇',
          options: tagOptions.value
        },
        {
          type: FilterType.SELECTOR,
          label: '狀態',
          placeholder: '請選擇',
          options: [
            { label: '草稿', value: 'draft' },
            { label: '處理中', value: 'processing' },
            { label: '上架', value: 'published' },
            { label: '下架', value: 'taken_down' },
            { label: '已到期', value: 'expired' }
          ]
        }
      ];
    });

    /** 取得所有主類型文字 */
    const getClassificationsText = (classifications: Classification[]): string => {
      if (isEmpty(classifications)) {
        return '-';
      }
      return join(map(classifications, c => c.name), '、');
    };

    watch([startDate, endDate], ([startDate, endDate]) => {
      router.push({ query: { ...route.query, startDate, endDate } });
    });

    watch(
      () => route.query,
      (query) => {
        startDate.value = query.startDate || yesterdayStart as string;
        endDate.value = query.endDate || yesterdayEnd as string;

        if (startDate.value && endDate.value) {
          dateRange.value = [startDate.value, endDate.value];
          return;
        }
        dateRange.value = null;
      },
      { immediate: true }
    );

    return {
      page,
      data,
      sortKey,
      dateRange,
      sortOrder,
      isLoading,
      isFetching,
      filterOptions,
      WorkStatisticsSortKey,
      keyword,
      sortChange,
      setPageQuery,
      formatLocalTime,
      handleExcelExport,
      handleFilterChange,
      getThousandSeparator,
      getClassificationsText,
      handleDateRangeChange,
      checkStatusIsComingSoon,
      getStatusTagType
    };
  }
});
